import type { Context } from '@white-label-types/middleware';
import { PARTNER_TYPES } from '@white-label-configuration/constants';

/**
 * Client middleware, that checks that the logged in partner is of type Booking Portal and has a valid channel token
 *
 * @param {Context} context
 * @returns {Promise<any>}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const bookingPortalAuth = async ({ app: { $auth } }: Context): Promise<any> => {
  if (!process.client) {
    return false;
  }

  const isBookingPortal = process.env['NUXT_ENV_IS_BOOKING_PORTAL'] === 'true';

  if (isBookingPortal && $auth.isAuthenticated && $auth.user) {
    const partnerTypeId: number = parseInt($auth.user['https://user_metadata/partner_type_id'], 10) || 0;
    const channelToken: string = $auth.user['https://user_metadata/channel_token']?.toString() || '';
    const isInvalidPartner: boolean = partnerTypeId !== PARTNER_TYPES.bookingPortal || !channelToken;

    if (isInvalidPartner) {
      console.log('INVALID PARTNER');
      await $auth.logout();
    }
  }
};

export default bookingPortalAuth;
