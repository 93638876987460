import { AuthObject } from '@white-label-types/auth';
import { datadogRum } from '@datadog/browser-rum';
import { Route } from 'vue-router';
import { isDedicatedFlow, getUserPermissions } from './dedicated-flow';

interface AuthContext {
    redirect: (path: string) => void;
    $config: Record<string, string>;
    route: Route;
    $auth: AuthObject;
}

const AUTHORISED_ROLES = [
    'cavu-admin',
    'cavu-cs',
    'partner-admin',
    'partner-order-editor',
] as const;

const AUTHORISED_PERMISSIONS = [
    'adm:dedicated-wl:read', // used for admin extranet users only to bypass needing channel token
] as const;

/**
 * Shared auth0 middleware logic for dedicated flows
 * @param context The auth context containing redirect, config, route and auth
 * @param routeName Optional route name to check against (e.g. CHECKOUT)
 */
export const handleDedicatedAuth0 = async ({
    redirect,
    $config,
    route,
    $auth,
}: AuthContext, routeName?: string): Promise<void> => {
    // Check if we're in a dedicated flow for the specified route or any dedicated flow
    if (isDedicatedFlow(route, routeName)) {
        // Check if logged in with correct strategy - partner
        if ($auth.user && $auth.isAuthenticated) {
            // check the user has authorised roles to access dedicated flow
            const userRoles = $auth.user['https://user_metadata/roles'][0];
            const isUserAuthorised = AUTHORISED_ROLES.some(role =>
                userRoles?.includes(role)
            );

            // restrict unauthorised users access to non-dedicated WL only
            if (!isUserAuthorised) {
                // log to datadog
                datadogRum.addError(
                    new Error('Auth isDedicatedFlow:', { cause: new Error('User is not authorised') }),
                    {
                        file: 'white-label-helper > dedicated-flow > isDedicatedFlow()',
                        route: route.name || '',
                        user: $auth.user
                    }
                );
                // redirect and keep logged in
                redirect('/');
            }

            // Check the user has the correct Permissions to access dedicated flow
            // Only Admin extranet users can bypass the channel token check
            const token: string | null = await $auth.getToken(); // bearer token contains the user's permissions
            let userHasPermission: boolean = false;
            if (token) {
                userHasPermission = AUTHORISED_PERMISSIONS.some(permission =>
                    getUserPermissions(token).includes(permission)
                );
            }

            // if the user does have permissions, it means they've come from Admin extranet and don't need to check channel token
            if (!userHasPermission) {
                // check the user has the correct channel token matching the partner
                const userChannelToken: string = $auth.user['https://user_metadata/channel_token'];
                const appChannelToken: string = $config['NUXT_ENV_AFFILIATE_APPLICATION_TOKEN'];
                const isChannelAuthorised: boolean = !!(userChannelToken && appChannelToken) && (userChannelToken === appChannelToken);

                if (!isChannelAuthorised) {
                    datadogRum.addError(
                        new Error('Dedicated flow - User channel token does not match'),
                    {
                        file: 'dedicated-auth.ts helper',
                        userChannelToken,
                        appChannelToken,
                        isChannelAuthorised
                    }
                );
                    await $auth.logout();
                }
            }
        } else {
            // un-authenticated / un-authorised
            // log to datadog
            datadogRum.addError(
                new Error('Auth isDedicatedFlow:', { cause: new Error('User is un-authenticated / un-authorised') }),
                {
                    file: 'white-label-helper > dedicated-flow > isDedicatedFlow()',
                    route: route.name || '',
                    user: $auth.user
                }
            );
            redirect('/');
        }
    }

};
