import Vue from 'vue';
import { NAMED_ROUTES } from '@white-label-configuration/constants';

Vue.mixin({
  methods: {
    $goHome() {
      this.$router.push(NAMED_ROUTES.home);
    },

    $openModalAfterRedirect(modalData, callback) {
      this.$store.commit('modalData/storeModalData', modalData);
      callback();
    },
  },
});
