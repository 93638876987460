import type { Context } from '@white-label-types/middleware';
import type { AuthObject } from '@white-label-types/auth';

import { isDedicatedFlow } from '@white-label-helper/dedicated-flow';
import { datadogRum } from '@datadog/browser-rum';
import { DEDICATED_WL_ROUTES_PATH } from '@white-label-configuration/constants';

interface ContextWithInstalledModules extends Context {
  $auth: AuthObject;
}

const dedicatedSSO = async ({
  redirect,
  query,
  $auth,
  route,
}: ContextWithInstalledModules): Promise<unknown> => {
  if (!process.client) {
    return;
  }
  if (isDedicatedFlow(route) && query.sso) {
    const {
      token, tokenExpiration, modifyBookingPath,
    } = JSON.parse(atob(query.sso as string));

    await $auth.fetchUser(token);
    $auth.setUserToken(token, tokenExpiration);

    try {
      if ($auth.isAuthenticated) {
        const redirectPath = modifyBookingPath ?? DEDICATED_WL_ROUTES_PATH.INDEX;
        redirect(redirectPath);
      }
    } catch (error) {
      const context = {
        file: 'wl-ecom > middleware > dedicated-sso.ts',
        authObj: $auth || '',
        isAuthenticated: $auth?.isAuthenticated || '',
      }

      console.error('Dedicated Flow - Error authorising user', context);
      datadogRum.addError(
        new Error('Dedicated Flow - Error authorising user:', { cause: error }),
        context
      );

      try {
        await $auth.logout();
      } catch (error) {
        console.error(error)
        datadogRum.addError(
          new Error('Dedicated Flow - auth unable to logout:', { cause: error }));
      }
    }
  }
};

export default dedicatedSSO;
