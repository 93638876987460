const middleware = {}

middleware['auth'] = require('../src/middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['booking-portal-auth'] = require('../src/middleware/booking-portal-auth.ts')
middleware['booking-portal-auth'] = middleware['booking-portal-auth'].default || middleware['booking-portal-auth']

middleware['dedicated-auth0'] = require('../src/middleware/dedicated-auth0.ts')
middleware['dedicated-auth0'] = middleware['dedicated-auth0'].default || middleware['dedicated-auth0']

middleware['dedicated-sso'] = require('../src/middleware/dedicated-sso.ts')
middleware['dedicated-sso'] = middleware['dedicated-sso'].default || middleware['dedicated-sso']

middleware['maintenance'] = require('../src/middleware/maintenance.ts')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

export default middleware
