import type { Context } from '@white-label-types/middleware';
import { AuthObject } from '@white-label-types/auth';
import { DEDICATED_WL_ROUTES } from '@white-label-configuration/constants';
import { handleDedicatedAuth0 } from '@white-label-helper/dedicated-flow';

interface ContextWithInstalledModules extends Context {
    $auth: AuthObject;
}

const dedicatedAuth0 = async (context: ContextWithInstalledModules): Promise<void> => {
    await handleDedicatedAuth0(context, DEDICATED_WL_ROUTES.CHECKOUT);
};

export default dedicatedAuth0;
